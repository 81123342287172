import { queryOptions, useQuery } from "@tanstack/vue-query";

export const useGetTestByIdQuery = (testId: string) => {
  return useQuery({
    ...getTestByIdQuery(testId),
  });
};

export const getTestByIdQuery = (testId: string) => {
  return queryOptions({
    queryKey: ["test", testId],
    queryFn: async () => {
      const response = await useApi().test[":testId"].$get({
        param: {
          testId,
        },
      });

      const data = await response.json();
      return data;
    },
    staleTime: Infinity,
  });
};
